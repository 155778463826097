import {graphql, Link} from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import * as React from 'react'
import Layout from '../../components/layout'
import QuickTake from "../../components/quickTake";

const ReviewPost = ({data}) => {
  const fm = data.mdx.frontmatter

  console.log(fm)

  return (
    <Layout 
      pageTitle={fm.title}
      pageSummary={fm.summary}
      pageDate={fm.date}
      pageAuthor={'Tycoon'}
      pageAuthorLink={'https://twitter.com/ChaseBuhler'}
      hero_image={fm.hero_image}
      hero_image_alt={fm.hero_image_alt}
      hero_image_credit_text={fm.hero_image_credit_text}
      hero_image_credit_link={fm.hero_image_credit_link}
      >
        <div>
          <MDXRenderer>
            {data.mdx.body}
          </MDXRenderer>
          <hr className={"mt-5 mb-5"} />
          <QuickTake take={{
            poster_link: fm.poster_link,
            rating: fm.rating,
            take: fm.take
          }} />
          <div className={'text-center mt-5'}>
            <Link to={'/reviews'} >More reviews</Link>
          </div>
        </div>
    </Layout>
  )
}

export const query = graphql`
query ($id: String) {
  mdx(id: {eq: $id}) {
    frontmatter {
      title
      date(formatString: "MMMM D, YYYY")
      summary
      rating
      take
      hero_image_alt
      hero_image_credit_link
      hero_image_credit_text
      hero_image {
        childImageSharp {
          gatsbyImageData
        }
      }
      poster_link {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP]
          )
        }
      }
    }
    body
  }
}
`
export default ReviewPost