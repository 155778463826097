import * as React from 'react'
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const QuickTake = ({take}) => {
    let colorClass;
    let iconClass;
    if (take.rating >= 9) {
        colorClass = "gold-star"
        iconClass = "fa fa-star"
    }
    else if (take.rating >= 5) {
        colorClass = "green-star"
        iconClass = "fa fa-solid fa-thumbs-up"
    }
    else {
        colorClass = "red-star"
        iconClass = "fa fa-solid fa-thumbs-down"
    }

    return (
        <div className="row">
            <div className="col">
                {take.poster_link && <div className={"quick-take-poster m-auto"}>
                    <GatsbyImage image={getImage(take.poster_link)} className="img-fluid rounded" alt={take.title + " poster."}/>
                </div>}
                <div className="quick-take-column text-center">
                    <div>
                        <h5>{take.title}</h5>
                        <h1 className={colorClass + " fw-bolder"}><i className={iconClass + " me-1 my-2"}></i><br/>{take.rating}/10</h1>
                        <p>{take.take}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuickTake;